function caisseVenteController($uibModal, $translate, $scope, WS, $window, AppSettings, $filter) {
    'ngInject';
    const vm = this;
   
    vm.filter_type = 'code_a_barre';
    vm.type_entete = 'bl';
    vm.bg_color = {
        'bl': '#262b4e',
        'avoir': '#e89510',
        'commande': '#277850',
        'facture': '#6b0a56',
        'devis': '#067f97',
        'cadeaux': '#c9484a',
    }
    vm.asci_calc = [
        8,53,219,111,103,104,105,106,100,101,102,109,97,98,99,107,96,110,13
    ];
    vm.getGammes = function () {
        WS.get('gammes')
            .then(function (response) {
                vm.gammes = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getMarques = function () {
        WS.get('marques')
            .then(function (response) {
                vm.marques = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getFamilles = function () {
        WS.get('familles')
            .then(function (response) {
                vm.familles = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getSousfamilles = function () {
        WS.get('sousfamilles')
            .then(function (response) {
                vm.sousfamilles = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    /**
     * search product by filter
     */
    vm.searchProducts = () => {
        console.log('filter prod')
    };



    /**
     ** Gestion des événements de la page
     ** 
     */
     $window.addEventListener('keyup',e => {
        
        console.log('Yeap letter is pressd', e.keyCode);
        if(vm.asci_calc.includes(e.keyCode)){
            console.log('asci included')
            $(`#asci-${e.keyCode}`).addClass('calc-btn-hover');
            // Remove CSS class after 1 second
            setTimeout(function() {
                $(`#asci-${e.keyCode}`).removeClass('calc-btn-hover');
            }, 300);
        }
    
    })
}
export default {
    name: 'caisseVenteController',
    fn: caisseVenteController
};